import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './authentication/session.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public _router: Router,
    private sessionStorage: SessionService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
    let url = window.location.pathname;
    let splitData = url.split("/")
    let path = splitData[1]
    this.sessionStorage.set('given_url', path);
    const country = window.navigator.language;
    console.log("country",country)
    console.log("path",path)
    if ((country === 'ja-JP' && path != "en") || (country === 'ja' && path != "en")) {
      this.toastr.info("読み込み中....");
      window.location.href = 'https://plusgroup.biz/';
    } else {
      this.toastr.info("Welcome....")
    }
  }
}
