import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { WalletService } from '../wallet.service';
import { OrderService } from '../../orders/order.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  buttonDisabled = false;
  balance = [];
  overdue = {};
  credits = [];
  debits = [];
  orders = [];
  groups: any = [];

  constructor(private walletservice: WalletService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private orderservice: OrderService) { }

  ngOnInit(): void {
    this.loadCredits();
    this.loadDebits();
    this.activeOrders();
    this.loadOverdue();
    this.getGroup();
  }

  async loadBalance() {
    this.spinner.show();
    try {
      let balanceResult = await this.walletservice.getBalance();
      this.balance = balanceResult["data"]["wallet"];
    } catch (err) {
      this.toastr.error("Something went wrong while getting the balance");
    }
    this.spinner.hide();
  }

  async loadCredits() {
    try {
      let creditsResult = await this.walletservice.getCredits();
      this.credits = creditsResult["data"]["deposit"];
    } catch (err) {
      if (err.response.status = 404) {
        this.toastr.error('Something went wrong!', 'Error');
      }
      else {
        this.toastr.error("Something went wrong while getting the credits");
      }
    }
  }

  async loadDebits() {
    try {
      let debitsResult = await this.walletservice.getDebits();
      this.debits = debitsResult["data"]["deposit"];
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the debits");
      }
    }
  }

  async activeOrders() {
    try {
      let ordersResult = await this.orderservice.getOrders("", "");
      this.orders = ordersResult["data"]["orders"];
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the orders");
      }
    }
  }

  async loadOverdue() {
    try {
      let ordersResult = await this.orderservice.getOverdue();
      this.overdue = ordersResult["data"]["overdue"];
    } catch (err) {
      if (err.response.status != 404) {
        this.toastr.error("Something went wrong while getting the overdue");
      }
    }
  }


  async getGroup() {
    let response: any = await this.walletservice.getGroupDetails();
    if (!response) {
      return this.toastr.error("Something went wrong please try again");
    }
    this.groups = response.data.groups;
    this.groups = [...this.groups]
    let res = await this.walletservice.getBalance();
  }
  async showGroupUser(group_number) {

    this.balance = [];
    let group = group_number.group_number;
    this.spinner.show();
    let result = await this.walletservice.getgroup(group);
    this.buttonDisabled = true;
    this.balance = result["data"]["payment"];
    this.balance = [...this.balance];
    this.spinner.hide();
  }
}
