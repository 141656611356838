<footer class="footer">
    <div class="container-fluid p-0 d-flex justify-content-center footer-container p-0 p-md-5">
        <div class="container p-0" style="max-width: 1100px;">
            <div class="row p-0 m-0 w-100 clear-fix">
                <div class="col-md-5 p-0 px-4 pt-4 pb-2 p-md-4">
                    <div class="header">
                        <img src="assets/images/new-logo-small.png" class="footer-logo" alt="footer-logo">Plus Group Corporation
                    </div>
                    <div class="body">
                        <p>Direct access to the Japanese Auto Auctions
                        over 150,000 cars each week
                        making trade Simple, Easy, Safe, Transparent</p>
                    </div>
                </div>
                <div class="col-md-4 p-0 px-4 py-2 p-md-4">
                    <div class="header">
                        Contact Us
                    </div>
                    <div class="body">
                        <div>Call: +81 794 82 5020</div>
                        <div>Fax:  +81 50 3510 9220</div>
                        <div>WhatsApp: +81 80 3853 4666</div>
                        <div>Mail: info@plusgroup.jp</div>
                        <div>Address: 2110, Fukui, Miki City, Hyogo Ken, Japan</div>
                    </div>
                </div>
                <div class="col-md-3 p-0 px-4 pt-2 pb-4 p-md-4">
                    <div class="header xl-text">
                        Useful Links
                    </div>
                    <div class="body">
                        <div class="footer-link" routerLink="/about">About Us</div>
                        <div class="footer-link" routerLink="/jdw-to-usa">JDM to USA</div>
                        <div class="footer-link" routerLink="/how-to-buy">How to Buy</div>
                        <div class="footer-link" routerLink="/left-hand-drive">Left Hand Drive</div>
                        <div class="footer-link" routerLink="/help">Help</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>