<div class="container-fluid p-0 d-flex justify-content-center">
  <div class="container p-0 theme-setting p-4">
    <div class="xl-text theme-2-text theme-2-family weight-700">
      <img src="assets/images/enquires.png" class="mr-2"> My Enquiries
    </div>
    <div class="container-fluid p-0" style="overflow: auto;">
      <table class="w-100 mt-3 tab-bord" style="min-width: 700px;">
        <thead>
          <tr class="wa-head p-2 pl-3 pr-3">
            <th scope="col" class="p-2 pl-3 pr-3">Enquiry ID</th>
            <th scope="col" class="p-2 pl-3 pr-3">Stock No</th>
            <th scope="col" class="p-2 pl-3 pr-3">Car Name</th> 
            <th scope="col" class="p-2 pl-3 pr-3">Enquiry Date</th>
            <th scope="col" class="p-2 pl-3 pr-3">Messages</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let car of userEnquiry" class="b-white p-2 pl-3 pr-3">
            <td class="p-2 pl-3 pr-3">{{car.enquiry_id}}</td> 
            <td class="p-2 pl-3 pr-3"><a href="javascript:void(0)" [routerLink]="['/carshome/car']" [queryParams]= "{stock_no: car.stock_no}">{{car.stock_no}}</a></td>
            <td class="p-2 pl-3 pr-3">{{car.car_name}}</td>
            <td class="p-2 pl-3 pr-3">{{car.created_date | date:'dd-MM-yyyy  hh:mm a'}}</td>
            <td class="p-2 pl-3 pr-3">
              <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#viewMessage" (click)="updateEnquiry(car.enquiry_id,car.stock_no)">Open</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal fade message-modal" id="viewMessage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">View Message</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="body-chat">
        <div class="row p-0 m-0 w-100" *ngFor="let details of messages">
          <div class="col-10 p-0 ml-auto mt-3 text-right pr-2" *ngIf="user_id == details.user_id">
            <div class="admin-message p-3 d-inline-block">
              <div>{{details.first_name}}</div>
              {{details.message}}
            </div>
            <div class="chat-time">{{details.created_date | date:'yyyy-MM-dd hh:mm'}}</div>
          </div>
          <div class="col-10 p-0 mr-auto mt-3" *ngIf="user_id != details.user_id">
            <div class="user-message p-3 d-inline-block">
              <div>{{details.first_name}}</div>
              {{details.message}}
            </div>
            <div class="chat-time">{{details.created_date | date:'yyyy-mm-dd hh:mm'}}</div>
          </div>
        </div>
      </div>

      <div class="input-group m-t-30 flex"> 
        <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" [(ngModel)]="enquiryMessage">    
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="replyAdminMessage()">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>