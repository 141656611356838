export const environment = {
  production: false,
  url: "https://apiprod.plusgroup.jp/",
  stock_images: "https://pgjpassets.s3-us-west-2.amazonaws.com/stocks/",
  auction_images:"https://pgjpassets.s3.us-west-2.amazonaws.com/auctions/",
  documents: "https://pgjpassets.s3-us-west-2.amazonaws.com/documents/",
  plusgroup_images:"https://pgjpassets.s3.us-west-2.amazonaws.com/plusgroup/",
  api_key: "ninsxo6um26agg8F8Cv1nYUxPLpoctPi+WuJse2nWdTmL12JHAtT/jaW3f99jwKgsnrLTq0zWw5a68/KgeLDOw==",
  searchjapancars_url:"https://searchjapancars.com/"
};
