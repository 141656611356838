import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { WalletComponent } from './wallet/wallet/wallet.component';

const routes: Routes = [{
  path: 'authentication',
  loadChildren: () =>
    import('./authentication/authentication.module').then(
      m => m.AuthenticationModule
    )
}, {
  path: 'about',
  loadChildren: () =>
    import('./about/about.module').then(
      m => m.AboutModule
    )
}, {
  path: 'left-hand-drive',
  loadChildren: () =>
    import('./left-hand-drive/left-hand-drive.module').then(
      m => m.LeftHandDriveModule
    )
}, {
  path: 'jdm-to-usa',
  loadChildren: () =>
    import('./jdw-to-usa/jdw-to-usa.module').then(
      m => m.JdwToUsaModule
    )
}, {
  path: 'how-to-buy',
  loadChildren: () =>
    import('./how-to-buy/how-to-buy.module').then(
      m => m.HowToBuyModule
    )
},
{
  path: 'corporate',
  loadChildren: () =>
    import('./contact/contact.module').then(
      m => m.ContactModule
    )
}, {
  path: 'faq',
  loadChildren: () =>
    import('./faq/faq.module').then(
      m => m.FaqModule
    )
}, {
  path: 'profile',
  loadChildren: () =>
    import('./profile/profile.module').then(
      m => m.ProfileModule
    )
}, {
  path: 'wallet',
  loadChildren: () =>
    import('./wallet/wallet.module').then(
      m => m.WalletModule
    )
},
{
  path: 'help',
  loadChildren: () =>
    import('./help/help.module').then(
      m => m.HelpModule
    )
},
{
  path: '',
  redirectTo: '/home',
  pathMatch: 'full'
},
{
  path: 'home',
  loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
},
{
  path: 'en',
  loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
},
{
  path: 'cars',
  loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule)
},
// {
//   path: 'carshome',
//   loadChildren: () => import('./cars/cars.module').then(m => m.CarsModule)
// },
{
  path: 'orders',
  loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
},
{
  path: 'wallet',
  component: WalletComponent,
},
{
  path: 'enquiries',
  component: EnquiryComponent,
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", anchorScrolling: 'enabled', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


/*{
  path: 'enquiry',
  component: EnquiryComponent,
}*/