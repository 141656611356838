import { ApisService } from './../../apis.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener, ViewChild
} from '@angular/core';
import { RightSidebarService } from '../../services/rightsidebar.service';
import { FormControl } from '@angular/forms';
import { SessionService } from '../../authentication/session.service';
import { DatePipe } from '@angular/common';
import {Router, NavigationEnd  } from '@angular/router';
import { Location } from "@angular/common";

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMypage= false
  userLoggedIn = false;
  userName = "";
  jpTime = '';
  isHomeActive = false;
  isSearchActive = false;
  intervalRef = null;

  @ViewChild('navbarToggler') navbarToggler:ElementRef;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private sessionService: SessionService,
    public datepipe: DatePipe,
    private apisService: ApisService,
    private router: Router,
    public location: Location
  ) { }

  notifications: Object[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    },
    {
      userImg: 'assets/images/user/user4.jpg',
      userName: 'Ashton Cox',
      time: '5 hours ago',
      message: 'Lets break for lunch...'
    },
    {
      userImg: 'assets/images/user/user5.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user6.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user7.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    }
  ];

  selected = new FormControl(0);

  ngOnInit() {
    this.setStartupStyles();
    let user = this.sessionService.get("user_id");
    this.userName = this.sessionService.get("user_name") || "";
    if(user && this.userName){
      this.userLoggedIn = true;
    }
    this.sessionService.usedLoggedIn.subscribe(this.userLoginStatus);
    this.calcTime();
  }

  collapseNav() {
    this.navbarToggler.nativeElement.click();

  }

  expire_login(){
    this.navbarToggler.nativeElement.click();
    this.sessionService.clear();
    localStorage.clear();
  }

  userLoginStatus(userLoggedIn: boolean){
    this.userLoggedIn = userLoggedIn;
    //TODO: Signout
  }

  setStartupStyles() {
    //set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, 'dark');
      this.renderer.removeClass(this.document.body, 'light');
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      //TODO: SETTING THEME
      this.renderer.removeClass(this.document.body, 'theme-black');
      this.renderer.addClass(this.document.body, 'theme-white');
    }

    // set light sidebar menu on startup
    if (localStorage.getItem('menu_option')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menu_option')
      );
    } else {
      //TODO: SETTING THEME
      this.renderer.addClass(this.document.body, 'menu_light');
    }

    // set logo color on startup
    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(this.document.body, 'logo-white');
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.userLoggedIn = true;
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }

  public signout():void {
    this.userLoggedIn = false;
    this.sessionService.clear();
  }

  calcTime() {
    let japanTime = new Date(this.apisService.calculateJapanTime())
    this.jpTime = this.datepipe.transform(japanTime, 'H:mm:ss, EEEE');
    this.intervalRef = setInterval(() => {
      this.calcTime();
      clearInterval(this.intervalRef);
    }, 1000);
  }
  afterLogin(){
    this.showMypage = !this.showMypage
  }
}
