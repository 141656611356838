import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '@environment/environment';
import { SessionService } from '../authentication/session.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  url = environment['url'];
  apiKey = environment['api_key'];
  axiosConfig = {}

  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }
    };
  }
  get tokenConfig() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token
      }
    };
  }

  get token(): string {
    return this.sessionService.get('loginToken');
  }

  async getBalance(): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      return axios.get(`${this.url}/payments/all_payment?user_id=${user_id}`, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
      return e.response.data;
    }
  }

  async getCredits(): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let transaction_type = "CREDIT";
      let limit = 10;
      let page_no = 1;
      let url = `${this.url}/user_deposit/user_deposit?user_id=${user_id}&transaction_type=${transaction_type}&page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getDebits(): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let transaction_type = "DEBIT";
      let limit = 10;
      let page_no = 1;
      let url = `${this.url}/user_deposit/user_deposit?user_id=${user_id}&transaction_type=${transaction_type}&page_no=${page_no}&limit=${limit}`;
      return axios.get(url, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getgroup(group_number?: string): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      return axios.get(`${this.url}/payments/all_payment?group_number=${group_number} &user_id=${user_id}`, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getGroupDetails(): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/user_wallet/all_groups?user_id=${user_id}`;
      return axios.get(url, this.axiosConfig);
    } catch (error) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getPayments() {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/manager/get_admin_accept_payments?user_id=${user_id}`;
      return axios.get(url, this.axiosConfig);
    } catch (error) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getdocumentsUrl(indexvalue, filetype) {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/manager/get_documentsurl?index_value=${indexvalue}&user_id=${user_id}&file_key=${filetype}`;
      return axios.get(url, this.axiosConfig);
    } catch (error) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }
}
