<nav #navbar class="navbar sticky-top navbar-expand-lg navbar-light theme-bg">
    <div class="container-fluid p-0 theme-container header-bar">
        <img src="assets/images/new-logo.png" alt="" class="header-new-icon mt-1 d-md-none"/>
        <button #navbarToggler class="navbar-toggler d-md-none collapsed float-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse pt-5 d-md-none d-lg-block d-xl-block">
            <div class="row p-0 m-0">
                <div class="col-auto p-0 d-flex justify-content-end align-items-center cur d-md-none d-lg-block d-xl-block" routerLink="/home">
                    <img src="assets/images/new-logo.png" alt="" class="header-icon mt-2" />
                </div>
                <div class="col-auto d-flex justify-content-end align-items-center cur d-block d-lg-none" routerLink="/home">
                    <img src="assets/images/new-logo-small.png" alt="" class="header-icon" />
                </div>
                <div class="col-auto ml-auto header-content d-flex align-items-center">
                    <ul class="navbar-nav nav mr-auto">
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/home" [ngClass]="{'active-nav': location.path() == '/home'}">
                                Home
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/about" routerLinkActive="active-nav">
                                About
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/jdm-to-usa" routerLinkActive="active-nav">
                                JDM to USA
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/how-to-buy" routerLinkActive="active-nav">
                                How to Buy
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/left-hand-drive" routerLinkActive="active-nav">
                                Left Hand Drive
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/help" routerLinkActive="active-nav">
                                Help
                            </span>
                        </li>
                        <li class="nav-item nav-notification-icons">
                            <button mat-button disableRipple class="theme-2-text nav-notification-icons">
                                <li class="dropdown user_profile">
                                    <a href="#" style="color:  #212529" onClick="return false;" data-toggle="dropdown" role="button" class="nav-notification-icons" (click)="afterLogin()">
                                        My Page
                                        <!-- <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User"> -->
                                    </a>
                                    <ul class="dropdown-menu pullDown">
                                        <li class="body">
                                            <ul class="user_dw_menu">
                                                <li *ngIf="!userLoggedIn">
                                                    <a routerLink="/authentication/signin">
                                                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Login
                                                    </a>
                                                </li>
                                                <!-- <li *ngIf="userLoggedIn">
                                                    <a routerLink="/profile">
                                                        <mat-icon aria-hidden="false" class="mr-2">account_box</mat-icon>My Profile
                                                    </a>
                                                </li>
                                                <li *ngIf="userLoggedIn">
                                                    <a routerLink="/wallet">
                                                        <mat-icon aria-hidden="false" class="mr-2">account_balance</mat-icon>My Wallet
                                                    </a>
                                                </li>
                                                <li *ngIf="userLoggedIn">
                                                    <a routerLink="/orders/orders">
                                                        <mat-icon aria-hidden="false" class="mr-2">shop</mat-icon>Orders
                                                    </a>
                                                </li>
                                                <li *ngIf="userLoggedIn">
                                                    <a href="/enquiries">
                                                        <mat-icon aria-hidden="false" class="mr-2">question_answer</mat-icon>My Enquiries
                                                    </a>
                                                </li>
                                                <li *ngIf="userLoggedIn">
                                                    <a routerLink="/bids">
                                                        <mat-icon aria-hidden="false" class="mr-2">message</mat-icon>My Bids
                                                    </a>
                                                </li>
                                                <li>
                                                    <a routerLink="/faq">
                                                        <mat-icon aria-hidden="false" class="mr-2">help</mat-icon>help
                                                    </a>
                                                </li>
                                                <li *ngIf="userLoggedIn">
                                                    <a routerLink="/authentication/signin" (click)="signout()">
                                                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                                                    </a>
                                                </li> -->
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-auto p-0 pt-2" *ngIf="!userLoggedIn">
                    <a routerLink="/authentication/signup" class="p-1 pl-3 pr-3 active-nav">Sign Up</a>
                </div>
            </div>
        </div>


        <div class="collapse navbar-collapse p-0 pt-3" id="navbarSupportedContent">
            <div class="p-3 nav-item nav-notification-icons m-0" routerLink="/home" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    Home
                </span>
            </div>
            <div class="p-3 nav-item nav-notification-icons m-0"routerLink="/about" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    About
                </span>
            </div>
            <div class="p-3 nav-item nav-notification-icons m-0"routerLink="/jdm-to-usa" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    JDM to USA
                </span>
            </div>
            <div class="p-3 nav-item nav-notification-icons m-0" routerLink="/how-to-buy" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    How to Buy
                </span>
            </div>
            <div class="p-3 nav-item nav-notification-icons m-0" routerLink="/left-hand-drive" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    Left Hand Drive
                </span>
            </div>
            <div class="p-3 nav-item nav-notification-icons m-0" routerLink="/help" (click)="collapseNav()">
                <span class="p-1 pl-3 pr-3 theme-2-text res-nav">
                    Help
                </span>
            </div>

            <div class="pt-3 mb-3 mr-0 nav-item accordion" id="accordionUser">
                <span class="p-1 pl-3 pr-3 ml-3 theme-2-text res-nav" data-toggle="collapse" data-target="#collapseUser" aria-expanded="true" aria-controls="collapseUser">
                    My Page
                </span>

                <div id="collapseUser" class="collapse mt-3 page-container" data-parent="#accordionUser">
                    <div class="p-3 pl-5 theme-2-text res-nav" *ngIf="!userLoggedIn" routerLink="/authentication/signin" (click)="collapseNav()">
                        Login
                    </div>
                    <div class="p-3 pl-5 theme-2-text res-nav" *ngIf="userLoggedIn" routerLink="/profile" (click)="collapseNav()">
                        My Profile
                    </div>
                    <div class="p-3 pl-5 theme-2-text res-nav" *ngIf="userLoggedIn" routerLink="/wallet" (click)="collapseNav()">
                        My Account
                    </div>
                    <div class="p-3 pl-5 theme-2-text res-nav" *ngIf="userLoggedIn" routerLink="/orders/orders" (click)="collapseNav()">
                        Orders
                    </div>
                    <div class="p-3 pl-5 theme-2-text res-nav" *ngIf="userLoggedIn" routerLink="/enquiries" (click)="collapseNav()">
                        Enquiry
                    </div>
                    <div class="pt-3 pl-5 theme-2-text res-nav" routerLink="/faq" (click)="collapseNav()">
                        Help
                    </div>
                    <div class="py-3 mt-3 pl-5 theme-2-text res-nav" *ngIf="userLoggedIn" routerLink="/authentication/signin" (click)="expire_login()">
                        Logout
                    </div>
                </div>
            </div>
            <div class="p-3 nav-item border-top pl-4" *ngIf="!userLoggedIn">
                <a routerLink="/authentication/signup" class="sign-up-button p-2 pl-3 pr-3" (click)="collapseNav()">Sign Up</a>
            </div>
        </div>
    </div>
</nav>

<div class="sticky-top time-nav pb-1 d-flex justify-content-center">
    <div class="theme-container header-width d-none d-md-block max-width">
        <div class="row p-0 m-0 theme-2-text font-12 theme-2-family h-100">
            <div class="col-md-3 p-0 mr-auto bd-highlight d-flex align-items-end">
                <span *ngIf="userLoggedIn">Welcome <span class="text-capitalize">{{userName}}</span></span>
            </div>
            <div class="col-md-3 p-0 bd-highlight pl-2 pr-2 d-flex align-items-end">
                <img src="assets/images/bussiness.png" alt="bussiness-icon" class="footer-icon mr-2">Business Hours Mon-Fri 10:00-17:00
            </div>
            <div class="col-md-3 p-0 bd-highlight pl-2 pr-2 d-flex justify-content-center align-items-end">
                <img src="assets/images/call.png" alt="call-icon" class="footer-icon mr-2"> Call Us +81 80 3853 4666
            </div>
            <div class="col-md-3 p-0 bd-highlight pl-2 d-flex justify-content-end align-items-end">
                <img src="assets/images/flag.png" alt="flag-icon" class="flag-icon">Japan Time: {{jpTime}}
            </div>
        </div>
    </div>
    <div class="theme-container header-width d-block d-md-none">
        <div class="row p-0 m-0 w-100">
            <div class="col-md-3 p-0 res-highlight pl-2 mt-2">
                <img src="assets/images/flag.png" alt="flag-icon" class="flag-icon border mr-2">Japan Time: {{jpTime}}
            </div>
            <div class="col-md-3 p-0 res-highlight pl-2 pr-2 mt-2">
                <img src="assets/images/bussiness.png" alt="bussiness-icon" class="footer-icon mr-2">Business Hours Mon-Fri 10:00-17:00
            </div>
        </div>
    </div>
</div>
<div class="pt-3 mb-3 nav-item accordion" style = "padding-left: 40%;" data-parent="#afterLogin" *ngIf="userLoggedIn && showMypage ">
    <ul>
        <li>
            <span class="p-1 pl-3 pr-3  theme-2-text" routerLink="/profile"  routerLinkActive="active-nav"(click)="collapseNav()">
                My Profile
            </span>
            <span class="p-1 pl-3 pr-3  theme-2-text"  routerLink="/wallet"  routerLinkActive="active-nav" (click)="collapseNav()">
                My Account
            </span>
            <span class="p-1 pl-3 pr-3  theme-2-text"  routerLink="/orders/orders"  routerLinkActive="active-nav"(click)="collapseNav()">
                My Orders
            </span>
            <span class="p-1 pl-3 pr-3 theme-2-text"  routerLink="/enquiries" routerLinkActive="active-nav" (click)="collapseNav()">
                My Enquiries
            </span>
            <span class="p-1 pl-3 pr-3 theme-2-text" routerLink="/faq"  routerLinkActive="active-nav"(click)="collapseNav()">
                Help
            </span>
            <span class="p-1 pl-3 pr-3  theme-2-text"  routerLink="/authentication/signin" (click)="expire_login()">
                Logout
            </span>
        </li>
    </ul>
</div>