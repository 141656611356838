import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private statusService = new BehaviorSubject<boolean>(false);
  usedLoggedIn = this.statusService.asObservable();



  // storage: any;
  constructor() {
    // if(this.storage == null || this.storage == undefined){
    //   this.storage = {};
    // }
   }

  set(key:string,value:string){
    sessionStorage.setItem(key, value);
    // this.storage[key]=value;
    this.statusService.next(true);
  }
  get(key:string):string{
    // return this.storage[key]
    return sessionStorage.getItem(key);
  }


  remove(key:string){
    // this.storage[key]=null;
    sessionStorage.removeItem(key);
    this.statusService.next(false);
  }
  clear(){
    // this.storage={};
    sessionStorage.clear();
    this.statusService.next(false);
  }
}

