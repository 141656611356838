<div class="container-fluid p-0 d-flex justify-content-center">
    <div class="row p-0 m-0 theme-setting d-flex justify-content-center">
        <div class="col-10 p-0 pt-4 pb-4">
            <div class="xl-text theme-2-text theme-2-family weight-700">
                <img src="assets/images/wallet.png" class="mr-2"> My Account
            </div>
            <div class="container-fluid p-0" style="overflow: auto;">
                <table class="mt-3 w-100 tab-bord" style="min-width: 700px;">
                    <thead>
                        <tr class="wa-head">
                            <th scope="col"> S/no</th>
                            <th scope="col">Stock No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Balance</th>
                            <th scope="col">INV</th>
                            <th scope="col">Dereg</th>
                            <th scope="col">Insp</th>
                            <th scope="col">B/L</th>
                            <th scope="col">Tracl</th>
                            <th scope="col">Remarks</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let bl of balance; let i =index" >
                           
                                <td>{{i+1}}</td>
                                <td>{{bl.stock_no}}</td>
                                <td>{{bl.name}}</td>
                                <td>{{bl.payment_date }}</td>
                                <td>{{bl.amount}}</td>
                                <td>{{bl.payment}}</td>
                                <td>{{bl.balance}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{bl.remarks}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="bd-example row m-t-100">
                <div class="container photos-container col-md-4 mt-4">
                    <div class="lg-text theme-2-text theme-2-family weight-700">Payment Plan</div>
                    <table class="pl-2 pr-2 w-100 tab-bord">
                            <tr class="wa-head p-2 pl-3 pr-3">
                                <th scope="col" class="p-2 pl-3 pr-3">Payment Method</th>
                                <th scope="col" class="p-2 pl-3 pr-3">Overdue</th>
                            </tr>
                            <tr *ngIf="overdue" class="b-white p-2 pl-3 pr-3">
                                <td class="p-2 pl-3 pr-3">CASH</td>
                                <td class="p-2 pl-3 pr-3">{{overdue.total_due ? overdue.total_due : '-'}}</td>
                            </tr>
                    </table>
        
                    <table class="pl-2 pr-2 w-100 mt-3 tab-bord">
                        <thead>
                            <tr class="wa-head p-2 pl-3 pr-3">
                                <th scope="col" class="p-2 pl-3 pr-3">Due Date</th>
                                <th scope="col" class="p-2 pl-3 pr-3">Invoice No</th>
                                <th scope="col" class="p-2 pl-3 pr-3">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of orders" class="b-white p-2 pl-3 pr-3">
                                <td class="p-2 pl-3 pr-3">{{order.due_date ? order.due_date : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{order.invoice_number ? order.invoice_number : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{order.total_amount ? order.total_amount : '-'}} JPY</td>
                            </tr>
                        < <td><a href="javascript:void(0)" routerLink="/orders/invoice">1322342349</a></td> -
                        </tbody>
                    </table>
                </div>
                <div class="container photos-container col-md-4 mt-4">
                    <div class="lg-text theme-2-text theme-2-family weight-700">Recent credits</div>
                    <table class="pl-2 pr-2 w-100 tab-bord">
                        <thead>
                            <tr class="wa-head p-2 pl-3 pr-3">
                                <th scope="col" class="p-2 pl-3 pr-3">Date</th>
                                <th scope="col" class="p-2 pl-3 pr-3">Amount <span class="m-l-10">(JPY)</span></th>
                                <th scope="col" class="p-2 pl-3 pr-3">Balance <span class="m-l-10">(JPY)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let credit of credits" class="b-white p-2 pl-3 pr-3">
                                <td class="p-2 pl-3 pr-3">{{credit.payment_date ? credit.payment_date : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{credit.amount ? credit.amount : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{credit.balance ? credit.balance : '-'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="container photos-container col-md-4 mt-4">
                    <div class="lg-text theme-2-text theme-2-family weight-700">Recent debits</div>
                    <table class="pl-2 pr-2 w-100 tab-bord">
                        <thead>
                            <tr class="wa-head p-2 pl-3 pr-3">
                                <th scope="col" class="p-2 pl-3 pr-3">Date</th>
                                <th scope="col" class="p-2 pl-3 pr-3">Amount <span class="m-l-10">(USD)</span></th>
                                <th scope="col" class="p-2 pl-3 pr-3">Balance <span class="m-l-10">(USD)</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let debit of debits" class="b-white p-2 pl-3 pr-3">
                                <td class="p-2 pl-3 pr-3">{{debit.payment_date ? debit.payment_date : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{debit.amount ? debit.amount : '-'}}</td>
                                <td class="p-2 pl-3 pr-3">{{debit.balance ? debit.balance : '-'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
        </div>
    </div>
</div>