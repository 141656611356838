import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApisService } from '../apis.service';
import { SessionService } from '../authentication/session.service';
declare const $: any;

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {

  userEnquiry = [];
  messages = [];
  user_id: any;
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private apisService: ApisService,
    private sessionservice: SessionService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user_id = this.sessionservice.get('user_id');
    await this.getUserEnquiries();
    $('#viewMessage').on('hidden.bs.modal', (e) => {
      this.messages = [];
    });
  }

  async getUserEnquiries() {
    try {
      this.spinner.show();
      this.userEnquiry = await this.apisService.getEnquiry(this.user_id);
      this.spinner.hide();
    } catch (err) {
      if (err.response.status = 404) {
        this.toastr.error("Data not found");
      }
      else {
        this.toastr.error("Something went wrong while getting the orders");
      }
    }
  }

  enquiry_id: any;
  stock_no: any;
  enquiryMessage: any;

  async updateEnquiry(enquiry_id, stock_no) {
    this.enquiry_id = enquiry_id;
    this.stock_no = stock_no;
    await this.getMessages();
  }

  async replyAdminMessage() {
    try {
      await this.apisService.enquiryUserReply(this.stock_no, this.user_id, this.enquiryMessage);
      this.messages.push({
        created_date: new Date(),
        message: this.enquiryMessage,
        user_id: this.user_id
      })
      this.enquiryMessage = '';
    } catch (err) {
      this.toastr.error("Unable to send the message to user. Please try again.");
    }
  }

  async getMessages() {
    try {
      let messageDetails = await this.apisService.getEnquiryMessages(this.enquiry_id);
      messageDetails.forEach((msgDet) => {
        this.messages.unshift(msgDet);
      });
    } catch (err) {
      if (err.response.status == 404) {
        this.toastr.error("Data not found");
      }
      else {
        this.toastr.error("Unable to load the messages from user. Please try again.");
      }
    }
  }

}
