import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '@environment/environment';
import { SessionService } from '../authentication/session.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  url = environment['url'];
  apiKey = environment['api_key'];
  axiosConfig = {}
  tokenConfig = {}
  documentPath = environment['documents'];
  
  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }
    };
    let verifyToken = this.sessionService.get('loginToken');
    this.tokenConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': verifyToken
      }
    }
  }

  async getOrders(start_date, end_date): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/manager/customer_orders?user_id=${user_id}&start_date=${start_date}&end_date=${end_date}`;
      return axios.get(url, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getOverdue(): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let url = `${this.url}/orders/overdue?user_id=${user_id}`;
      return axios.get(url, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getOrder(invoice_number, user_id): Promise<void> {
    try {
      let url = `${this.url}/orders/view_orders?invoice_number=${invoice_number}&user_id=${user_id}`;
      return axios.get(url, this.tokenConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getDocumentDetails(invoice_number, user_id): Promise<void> {
    try {
      let url = `${this.url}/manager/list_documents?invoice_number=${invoice_number}&user_id=${user_id}`;
      let listResult = await axios.get(url, this.axiosConfig);
      let list_result = listResult.data.documents;
      let list: any = [];
      for (let index in list_result) {
        let convertDocumentList = list_result[index];
        let list_documents = [];
        let images = (Array.isArray(convertDocumentList["document"])) ? convertDocumentList["document"] : [convertDocumentList["document"]]
        for (let index in images) {
          let documentsList = images[index];
          documentsList = this.documentPath + convertDocumentList["invoice_number"] + "/" + convertDocumentList["document"];
          list_documents = documentsList;
        }
        convertDocumentList["documents_lists"] = list_documents;
        list.push(convertDocumentList);
      }
      return list;
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async createOrder(car_id): Promise<void> {
    try {
      let user_id = this.sessionService.get("user_id");
      let buyer_id = parseInt(user_id)
      let url = `${this.url}/manager/order`;
      let payload = { car_id: car_id, user_id: buyer_id };
      return axios.put(url, payload, this.tokenConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async viewInvoice(invoice_number, user_id): Promise<void> {
    try {
      let url = `${this.url}/manager/invoice?invoice_number=${invoice_number}&user_id=${user_id}`;
      return axios.get(url, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }
}
