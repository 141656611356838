import { Injectable, EventEmitter, Pipe } from '@angular/core';
import axios from 'axios';
import * as moment from 'moment';
import 'moment-timezone';
import { SessionService } from './authentication/session.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'tester   '
})
export class ApisService {

  public scrollEmitter: EventEmitter<any> = new EventEmitter();
  url = environment['url'];
  apiKey = environment['api_key'];
  axiosConfig = {}
  otpTokenConfig = {};

  constructor(
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.apiKey,
      }
    };

  }
  get tokenConfig() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token
      }
    };
  }

  get token(): string {
    return this.sessionService.get('loginToken');
  }

  async signup({
    email,
    password,
    firstName,
    lastName,
    currency,
    role,
  }): Promise<void> {
    try {
      return axios.post(`${this.url}/users/user`, {
        "first_name": firstName,
        "last_name": lastName,
        "email_id": email,
        "password": password,
        "currency": currency,
        "role": role
      }, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async signin({
    email,
    password,
  }): Promise<void> {
    try {
      return axios.post(`${this.url}/users/login`, {
        "email_id": email,
        "password": password,
      }, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async changePassword(
    email,
    password,
    new_password
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/users/update_password`, {
        "email_id": email,
        "password": password,
        "new_password": new_password,
      }, this.tokenConfig);

    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }


  async forgotPassword(email) {
    try {
      return await axios.post(`${this.url}/users/forgot_password`,
        email, this.axiosConfig);
    } catch (error) {
      this.toastr.error('Something went wrong!', 'Error');
      return error.response.data;
    }
  }

  async verifyOtp(email, otp): Promise<void> {
    try {
      return await axios.post(`${this.url}/users/verify_otp`, {
        "email": email,
        "otp": otp
      }, this.axiosConfig);
    } catch (error) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }
  async setPassword(email, password, confirmPassword) {
    let verifyToken = await this.sessionService.get('verifyToken');
    this.otpTokenConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': verifyToken
      }
    };

    try {
      let result = await axios.put(`${this.url}/users/change_password`, {
        "email": email,
        "password": password,
        "confirmPassword": confirmPassword
      },
        this.otpTokenConfig);
      return result.data;
    } catch (error) {
      return error.response.data;
    }
  }
  async getUser(user_id): Promise<void> {
    try {
      return axios.get(`${this.url}/users/user?user_id=${user_id}`, this.axiosConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async getEnquiry(user_id, page_no = 1, limit = 30): Promise<any> {
    try {
      let enquiry_result = await axios.get(`${this.url}/enquiry/enquiries?user_id=${user_id}&page_no=${page_no}&limit=${limit}`, this.tokenConfig);
      return enquiry_result["data"]["enquiries"];
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }


  async updateUser(
    user_id,
    first_name,
    last_name,
    address,
    country,
    phone_no,
  ): Promise<void> {
    try {
      return axios.put(`${this.url}/users/user`, {
        "user_id": user_id,
        "first_name": first_name,
        "last_name": last_name,
        "address": address,
        "country": country,
        "phone_no": phone_no,
        "pincode": "0000"
      }, this.tokenConfig);
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  calculateJapanTime() {
    let d = new Date();
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (3600000 * 9.0));
    return nd;
  }

  calculateTimeRemaining(auctionTime) {
    auctionTime = moment(auctionTime, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
    let auctionTimeJST = moment.tz(auctionTime, "Asia/Tokyo");
    let timeToRestrict = auctionTimeJST.subtract(1, "hours");

    let currentTimeInJapan = moment.tz(moment(), "Asia/Tokyo");

    if (currentTimeInJapan > timeToRestrict) {
      return "0 hours";
    } else {
      let duration = moment.duration(timeToRestrict.diff(currentTimeInJapan));
      let result = "";
      let days = duration.days();
      let hours = duration.hours();
      let minutes = duration.minutes();
      // if()
      if (days != 0) {
        result += `${days} days `;
      }
      if (hours != 0) {
        result += `${hours} h `;
      }
      if (minutes != 0) {
        result += `${minutes} m `;
      }
      result = result || "0 hours";
      return result;
    }
  }
  async getEnquiryMessages(enquiry_id, page_no = 1, limit = 30): Promise<any> {
    try {
      let url = `${this.url}/enquiry/all_messages?enquiry_id=${enquiry_id}&page_no=${page_no}&limit=${limit}`;
      let get_message = await axios.get(url, this.axiosConfig);
      return get_message["data"]["messages"];
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async enquiryUserReply(
    stock_no,
    user_id,
    message,
  ): Promise<void> {
    try {
      let url = `${this.url}/enquiry/enquiry`;
      return axios.post(url, {
        "stock_no": stock_no,
        "message": message,
        "user_id": user_id
      }, this.axiosConfig);

    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }

  async lotNumbersDetails(lot_number, from_lot_number, to_lot_number, page_no, limit): Promise<void> {
    try {
      // lot_number["page_no"] = page_no.toString();
      // lot_number["limit"] = limit.toString();
      let url = `${this.url}/manager/lot_number_details?lot_number=${lot_number}&from_lot_number=${from_lot_number}&to_lot_number=${to_lot_number}&page_no=${page_no}&limit=${limit} `;
      let get_message: any = await axios.get(url, this.axiosConfig);
      return get_message;
    } catch (e) {
      this.toastr.error('Something went wrong!', 'Error');
    }
  }
}
